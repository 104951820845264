// extracted by mini-css-extract-plugin
export const header = "Header-module--header--3c3J6";
export const logoContentItem = "Header-module--logo-content-item--1Bs-b";
export const lvLogo = "Header-module--lv-logo--1eBm9";
export const coBrandingLogo = "Header-module--co-branding-logo--tEXdA";
export const btnsContentItem = "Header-module--btns-content-item--2Vaqs";
export const dropDown = "Header-module--drop-down--3Kh4U";
export const locationIcon = "Header-module--location-icon--2SPhv";
export const btn = "Header-module--btn--37j2b";
export const arrowDown = "Header-module--arrow-down--3evu6";
export const lang = "Header-module--lang--2icrs";
export const toggleLang = "Header-module--toggle-lang--2qNdz";
export const contactUs = "Header-module--contact-us--3gjf5";
export const login = "Header-module--login--3n3Te";
export const joinNow = "Header-module--join-now--2-2qe";
export const promo = "Header-module--promo--19mVX";
export const sticky = "Header-module--sticky--30A7Y";