// extracted by mini-css-extract-plugin
export const footer = "Footer-module--footer--1LEZJ";
export const container = "Footer-module--container--3XNmD";
export const extole = "Footer-module--extole--2XQpH";
export const linkContainer = "Footer-module--link-container--7hxPx";
export const linkColumn = "Footer-module--link-column--3v1Z6";
export const subtitle = "Footer-module--subtitle--22y5n";
export const footerColumnLink = "Footer-module--footer-column-link--Ysl1p";
export const socialLinkContainer = "Footer-module--social-link-container--1j9d7";
export const row = "Footer-module--row--3DUrM";
export const rightSpacer = "Footer-module--right-spacer--3cYxJ";
export const iconSpacer = "Footer-module--icon-spacer--hSyar";
export const legalContainer = "Footer-module--legal-container--2HglK";
export const disclaimer = "Footer-module--disclaimer--O0XGU";
export const link = "Footer-module--link--2uG0B";
export const rainbowBorder = "Footer-module--rainbow-border--2fFrA";
export const socialMediaRow = "Footer-module--social-media-row--3UqLW";
export const legalLinkContainer = "Footer-module--legal-link-container--1PwGx";