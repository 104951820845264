import qs from 'qs';

/*use this func. only if the URL's environment is included in the .env.development and .env.production*/
export const updateURLParam = urlProcessEnv => {
    if (typeof window !== 'undefined' && window.location.search !== '') {
        return urlProcessEnv + window.location.search;
    } else {
        return urlProcessEnv;
    }
};

export const convertEnvironmentLivongoLink = (link, ccid) => {
    // Define environment based on the host name
    const hostname = typeof window !== 'undefined' ? window.location.host : '';
    const queryString =
        typeof window !== 'undefined' ? window.location.search : '';
    let environment = '';

    if (
        hostname.includes('.integration.livongo.com') ||
        hostname.includes('.local.livongo.com')
    ) {
        environment = 'integration';
    } else if (hostname.includes('.preprod.livongo.com')) {
        environment = 'preprod';
    }

    /*
    Dealing with any URL that contains the word, "livongo.com". i.e https://signup.livongo.com 
    Most URL that contains the word, "livongo.com", should have an env integration or preprod 
    assigned to them. ex. https://signup.integration.livongo.com/ and https://signup.preprod.livongo.com/
    
    Not all livongo.com Urls have a INT or PREPROD environment. ex 
    https://content.livongo.com/docs/Terms_Service_Eng.pdf.
    */
    if (
        environment &&
        link.includes('.livongo.com') &&
        !link.includes(`.${environment}.livongo.com`)
    ) {
        link = link.replace('.livongo.com', `.${environment}.livongo.com`);
    }

    /* 
        Handle specific cases for 'my' or 'signup' in the link when on the tdoctest domain:
        https://signup.livongo.com redirects to ---> https://signup-ccmpreprod.tdoctest.com
        https://my.livongo.com/login reidrects to ---> https://my-ccmpreprod.tdoctest.com 
    */
    if (
        (link.includes('my.') || link.includes('signup.')) &&
        hostname.includes('.tdoctest.')
    ) {
        const subdomain = link.split('.')[0];
        link = `${subdomain}-${hostname.split('-')[1]}`;
    }

    link += queryString;

    // handle links from Contentful
    if (link[0] === '/' && hostname) {
        link = `${hostname}${link}`;
    }

    /*Concatenate any extra param into the link. i.e ?ccid=GEN&locale=es-US&utm=something*/
    if (ccid) {
        const searchParams = new URLSearchParams(link.split('?')[1]);
        if (!searchParams.get('ccid')) {
            searchParams.set('ccid', ccid);
            link = `${link.split('?')[0]}?${searchParams.toString()}`;
        }
    }

    return link;
};

export const getCloudinaryImageUrl = cloudinaryImage => {
    if (
        cloudinaryImage?.image &&
        Array.isArray(cloudinaryImage.image) &&
        cloudinaryImage.image.length > 0 &&
        cloudinaryImage.image[0].url
    ) {
        let url = cloudinaryImage.image[0].url;
        url = url.replace('http://', 'https://');

        return url;
    }
    return null;
};

export const getCloudinaryVideoUrl = cloudinaryVideo => {
    if (
        cloudinaryVideo?.video &&
        Array.isArray(cloudinaryVideo.video) &&
        cloudinaryVideo.video.length > 0 &&
        cloudinaryVideo.video[0].url
    ) {
        let url = cloudinaryVideo.video[0].url;
        url = url.replace('http://', 'https://');

        return url;
    }

    return null;
};

export const getCloudinaryObject = cloudinaryFile => {
    if (
        cloudinaryFile?.video &&
        Array.isArray(cloudinaryFile.video) &&
        cloudinaryFile.video.length > 0 &&
        cloudinaryFile.video[0].url
    ) {
        let url = cloudinaryFile.video[0].url.replace('http://', 'https://');
        const {width, height} = cloudinaryFile.video[0];
        return {
            url,
            width,
            height,
            name: cloudinaryFile.name,
        };
    }

    if (
        cloudinaryFile?.image &&
        Array.isArray(cloudinaryFile.image) &&
        cloudinaryFile.image.length > 0 &&
        cloudinaryFile.image[0].url
    ) {
        let url = cloudinaryFile.image[0].url.replace('http://', 'https://');
        const {width, height} = cloudinaryFile.image[0];
        return {
            url,
            width,
            height,
            name: cloudinaryFile.name,
        };
    }

    return null;
};

export const removeNullFromProps = props => {
    let newProps = {};

    for (var key in props) {
        if (props.hasOwnProperty(key) && props[key] !== null) {
            newProps[key] = props[key];
        }
    }

    return newProps;
};
